import React, { useEffect, useState } from "react";
import { Container, Image, Toast, Row, Col, CloseButton } from 'react-bootstrap';

export default function ImageUpload() {
    const [filedata, setFiledata] = useState('');
    const [show, setShow] = useState(false);
    const uploadHandler = (e) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setFiledata(reader.result);
            navigator.clipboard.writeText('<img width="300" src="' + reader.result + '">');
            setShow(true);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }



    }

    return (
        <Container className="mt-3">
            <div>
                <input type="file" className="input-file" name="imgUpload" accept="image/*" onChange={uploadHandler} />
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">Image copied</strong>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body>Woohoo, image base64 data copied!</Toast.Body>
                </Toast>
            </div>
            <Row className="justify-content-center m-5" >
                
                <Col md={8} style={{ overflow: 'hidden' }}>

                    <Image src={filedata} className="img-fluid" alt="This is an image" />
                </Col>
            </Row>

        </Container>
    );
}
