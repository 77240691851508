import { Container, Row, Col, Button, Card,  Form } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cloud from './TagCloud';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import configData from "./Config.json";
import uuid from 'react-native-uuid';


function Home({cookies}) {
  const [postLists, setPostList] = useState([]);
  useEffect(() => {
    getPosts();
  }, []);
  const getPosts = async () => {
    await axios.get(
      configData.BLOGURL,
      JSON.stringify({}),
      {
        headers: {
          "Content-Type": "application/json"

        }
      }
    ).then(res => {
      setPostList(res.data);
      //console.log(res.data)
    });
    //navigate("/");
    //{post.content} 
    //console.log(cookies.access_token);
  };
  return (




    <Container className='main'>
      <Row className='pt-2'>
        <Col className='new1' md={8}>
          {postLists.map((post) => {
      
            return (
              
              <Card key={post.uid} className='mt-2 mb-2' style={{  maxHeight: '300px', overflow:'hidden'}}>
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {post.tags.map((t) => {
                      return (
                        <Button variant="outline-secondary" className="m-2 btn-sm" key={uuid.v4()}>
                          {t}
                        </Button >
                      );
                    })}
                  </Card.Subtitle>


                  <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  <Card.Footer style={{  position:'absolute' , top:'10px', right:'10px'}}>





                    <Card.Link href={`/view?UID=${post.uid}`}>Read More</Card.Link>
                    {cookies.access_token ? <Card.Link  href={`/edit?UID=${post.uid}`}>Edit</Card.Link > : <></>}
                  </Card.Footer>
                </Card.Body>
              </Card>
              
            );
          })}



        </Col>
        <Col className='new2' md={4}>

          <Card className='mt-2 mb-2' >
            <Card.Body>
              <Card.Title>Search</Card.Title>
              <Form>
                <Row className="align-items-center">

                  <Form.Control
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Jane Doe"
                  />




                  <Button type="submit" className="mb-2">
                    Go
                  </Button>

                </Row>
              </Form>

            </Card.Body>
          </Card>

          <Card className='mt-2 mb-2' >
            <Card.Body>
              <Card.Title>Tag Cloud</Card.Title>

              <Cloud />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

  );
}

export default Home;
