import React,{useState, useEffect }  from "react";
import { Container, Button, InputGroup, Form, Col, CloseButton } from 'react-bootstrap';

function Contact(){
    return(
        <Container className="mt-3">
            <p>This is BugProducer, a coding lover. 
                <br />
            Contact me at admin@BugProducer.com
            </p>
        </Container>
    )
}

export default Contact;