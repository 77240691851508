import React, { useState, useEffect } from "react";
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Container, Button, InputGroup, Form, Col, CloseButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import uuid from 'react-native-uuid';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import configData from "./Config.json";
import { useNavigate, useSearchParams } from "react-router-dom";

function Edit({ cookies }) {
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({ value: '' });
  const [searchParams, setSearchParams] = useSearchParams();

  const [ckdata, setCkdata] = useState('<p>Input your blog content here</p><br /><br /><br /><br /><br /><br />');
  const [title, setTitle] = useState('');
  useEffect(() => {
    getBlog();
  }, []);
  const getBlog = async () => {
    if (searchParams.get("UID"))
      // console.log(searchParams.get("UID"));
      await axios.get(
        configData.ONEBLOGURL + searchParams.get("UID"),
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json"

          }
        }
      ).then(res => {
        // setPostList(res.data);
        //console.log(res.data);
        setTitle(res.data.title);

        setCkdata(res.data.content);

        let newtags = [];
        for (let i = 0; i < res.data.tags.length; i++) {
          if (res.data.tags[i])
            newtags.push({id: uuid.v4(), value:res.data.tags[i]});
        }

        setTags(newtags);
        console.log(res.data.tags);
      });

  };
  const navigate = useNavigate();
  const createPost = async () => {

    let newtags = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i])
        newtags.push(tags[i].value);
    }
    await axios.post(
      configData.BLOGURL,
      JSON.stringify({ "Title": title, "Content": ckdata, "Tags": newtags }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`

        }
      }
    ).then(res => {

      //console.log(res);
      setCkdata('<p>Input your blog content here</p><br /><br /><br /><br /><br /><br />');
      setTitle('');
    });
    navigate("/");
  };


  const deletePost = async () => {
    if(!window.confirm("Delete the item?"))
      return;
    let newtags = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i])
        newtags.push(tags[i].value);
    }
   // console.log(configData.BLOGURL+"/"+ searchParams.get("UID"));
    await axios.delete(
      configData.BLOGURL+"/"+ searchParams.get("UID"),
      
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`

        },
        date:{}
      }
    ).then(res => {

      //console.log(res);
      setCkdata('<p>Input your blog content here</p><br /><br /><br /><br /><br /><br />');
      setTitle('');
    });
    navigate("/");
  };

  
  
  const updatePost = async () => {
    let newtags = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i])
        newtags.push(tags[i].value);
    }
    console.log("update");
    await axios.put(
      configData.BLOGURL,
      JSON.stringify({ "Title": title, "Content": ckdata, "UID": searchParams.get("UID"), "Tags": newtags  }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`

        }
      }
    ).then(res => {

      //console.log(res);
      setCkdata('<p>Input your blog content here</p><br /><br /><br /><br /><br /><br />');
      setTitle('');
    });
    navigate("/");
  };
  const addtagHandler = (e) => {
    console.log(tag);
    let newtags = [...tags];
    newtags.push(tag);
    setTags(newtags);
    setTag({value:''});
  }

  const removetagHandler = (e) => {

    let newtags = [];
    for (let i = 0; i < tags.length; i++) {
      let t = tags[i];
      if (t.id != e)
        newtags.push(t);
    }
    setTags(newtags);
  }

  const tagchangeHandler = (e) => {

    setTag( {id: uuid.v4(), value:e.target.value} );
  }
  return (
    <Container >


      <Form.Control size="lg" value={title} type="text" placeholder="Title" className="mt-5 mb-3"
        onChange={(e) => {
          setTitle(e.target.value);
        }}

      />

      <Container >
        <Col md={4}>
          <InputGroup >


            <Button variant="outline-secondary" onClick={addtagHandler}>Add new tag</Button>
            <Form.Control type="text" placeholder="#JavaScript" onChange={tagchangeHandler} value={tag.value} />

          </InputGroup></Col>
        <Col md={12} className="mt-2" >

          {tags.map((t) => {
            return (
              <Button variant="outline-secondary" className="m-2" key={t.id}>
                {t.value}



                <CloseButton onClick={() => removetagHandler(t.id)} style={{ position: 'relative', top: '-8px', right: '-12px' }} />
              </Button >
            );
          })}

        </Col>
      </Container >

      <CKEditor
        editor={ClassicEditor}
        //config={ editorConfiguration }
        data={ckdata}


        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log({ event, editor, data });
          setCkdata(data);
        }}

      />
      <br />

      <div className="d-grid gap-2">
        <Button variant="primary" size="lg" onClick={searchParams.get("UID")?updatePost:createPost}>
          Submit Blog
        </Button>
        {searchParams.get("UID")?<Button variant="danger" size="lg" onClick={deletePost}>
          Delete Blog
        </Button>:<></>}
      </div>
    </Container>
  )
}

export default Edit;