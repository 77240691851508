import { NavDropdown, Container, Row, Col, Button, Navbar, Nav, Form,  Modal } from 'react-bootstrap';
import React, { useState } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home';
import StringTools from './StringTools';
import Contact from './Contact';
import Edit from './Edit';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import configData from "./Config.json";
import View from './View';
import ImageUpload from './ImageUpload';
import logo from './logo.png' 

function MyVerticallyCenteredModal({ show, onHide, cookies, setCookie, loginwithtext, setloginwithtext }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const [guid, setGuid] = useState("");
  const [code, setCode] = useState("");
  const loginHandler = (e) => {
    //e.preventDefault();
    try {
      const response = axios.patch(
        configData.LOGINURL,
        JSON.stringify({ "UserName": username, "Password": password }),
        {
          headers: {
            "Content-Type": "application/json"

          }
        }
      ).then(res => {
        if (res.data.status === "OK") {
          setGuid(res.data.guid);
          setloginwithtext(true);
          /* setCookie('access_token', res.data.token, {
             path: "/",
             maxAge: 24 * 3600
           });
           console.log(cookies.access_token);
           onHide();*/

        }
        else {
          console.log("login failed!");
          console.log(res);
        }
      });
      setUsername("");
      setPassword("");

    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else {
        console.log("Registration Failed");
      }

    }

  }

  const loginwithtextHandler = (e) => {
    //e.preventDefault();
    try {
      const response = axios.post(
        configData.LOGINURL,
        JSON.stringify({ "guid": guid, "Code": code }),
        {
          headers: {
            "Content-Type": "application/json"

          }
        }
      ).then(res => {
        if (res.data.status === "OK") {
          setCookie('access_token', res.data.token, {
             path: "/",
             maxAge: 12 * 3600
           });
           //console.log(cookies.access_token);
           onHide();

        }
        else {
          console.log("login failed!");
          console.log(res);
        }
      });
      setUsername("");
      setPassword("");

    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else {
        console.log("Registration Failed");
      }

    }

  }

  const usernameHandler = (e) => {
    setUsername(e.target.value);
    // console.log(e.target.value);
  }

  const codeHandler = (e) => {
    setCode(e.target.value);
    // console.log(e.target.value);
  }
  const passHandler = (e) => {
    setPassword(e.target.value);
    // console.log(e.target.value);
  }
  if (!loginwithtext)
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Log IN:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="fromUserName">
              <Form.Label column sm="2">
                UserName:
              </Form.Label>
              <Col sm="10">
                <Form.Control plaintext onChange={usernameHandler} value={username} style={{ borderColor: 'black', borderStyle:'solid', borderWidth:'1px'}} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
              <Form.Label column sm="2">
                Password:
              </Form.Label>
              <Col sm="10">
                <Form.Control type="password" placeholder="Password" onChange={passHandler} value={password} style={{ borderColor: 'black', borderStyle:'solid', borderWidth:'1px'}}/>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={loginHandler} >Log In</Button>

        </Modal.Footer>
      </Modal>

    );
  else
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enter SMS code to expire within 60 seconds:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="fromUserName">
              <Form.Label column sm="2">
                SMSCode:
              </Form.Label>
              <Col sm="10">
                <Form.Control plaintext value={code} onChange={codeHandler} style={{ borderColor: 'black', borderStyle:'solid', borderWidth:'1px'}}/>
              </Col>
            </Form.Group>


          </Form>
        </Modal.Body>
        <Modal.Footer>

          <Button onClick={loginwithtextHandler}>Validate</Button>
        </Modal.Footer>
      </Modal>
    )
}

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [loginwithtext, setloginwithtext] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const handleRemoveCookie = () => {
    removeCookie("access_token");
    setloginwithtext(false);
  }


  return (
    <>
    <Container><img  src={logo} style={{height:'100px'}} alt='logo' /></Container>
      <Router>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="/">BugProducer</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Tools" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/StringTools">String Tool</NavDropdown.Item>
                  <NavDropdown.Item href="/ImageUpload">ImageUpload</NavDropdown.Item>
                  <NavDropdown.Item href="/StringTools">TBD</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/Contact">Contact</Nav.Link>

              </Nav>
              <Nav>
                {!cookies.access_token ? <Nav.Link href="#" onClick={() => setModalShow(true)}>Log In</Nav.Link> :
                  <NavDropdown title="My Account" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/Edit">Create Blog</NavDropdown.Item>

                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#" onClick={handleRemoveCookie}>Log Out</NavDropdown.Item>
                  </NavDropdown>}

              </Nav>
            </Navbar.Collapse>


          </Container>
        </Navbar>
        <Routes>
          <Route path="/" element={<Home cookies={cookies} />} />
          <Route path="/StringTools" element={<StringTools />} />

          <Route path="/ImageUpload" element={<ImageUpload />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/View" element={<View />} />
          <Route path="/Edit" element={<Edit cookies={cookies} />} />
        </Routes>
      </Router>


      <MyVerticallyCenteredModal
        loginwithtext={loginwithtext}
        setloginwithtext={setloginwithtext}
        show={modalShow}
        onHide={() => setModalShow(false)}
        cookies={cookies}
        setCookie={setCookie}
      />
    </>
  );
}

export default App;
