import { Container, Row, Col, Button, Card, Navbar, Nav, Form, InputGroup, FormControl } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cloud from './TagCloud';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import configData from "./Config.json";
import uuid from 'react-native-uuid';
import { useNavigate, useSearchParams } from "react-router-dom";

function View() {
    const [tags, setTags] = useState([]);
    const [uid, setUid] = useState( '');
    const [searchParams, setSearchParams] = useSearchParams();  
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');

  useEffect(() => {
    getPost();
  }, []);
  const getPost = async () => {
    if (searchParams.get("UID"))
    // console.log(searchParams.get("UID"));
    await axios.get(
      configData.ONEBLOGURL + searchParams.get("UID"),
      JSON.stringify({}),
      {
        headers: {
          "Content-Type": "application/json"

        }
      }
    ).then(res => {
      // setPostList(res.data);
      //console.log(res.data);
      setTitle(res.data.title);

      setContent(res.data.content);

      let newtags = [];
      for (let i = 0; i < res.data.tags.length; i++) {
        if (res.data.tags[i])
          newtags.push({id: uuid.v4(), value:res.data.tags[i]});      }

      setTags(newtags);
      console.log(res.data.tags);
    });
  };
  return (




    <Container>
      <Row className='pt-2'>
        
      
              
              <Card  className='mt-2 mb-2' >
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                  {tags.map((t) => {
                      return (                      
                        <Button variant="outline-secondary" className="m-2 btn-sm" key={t.id}>
                         {t.value}
                        </Button >
                    );
                })}
                  </Card.Subtitle>


                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  <Card.Footer style={{  position:'absolute' , top:'10px', right:'10px'}}>
                    
                    
                  </Card.Footer>
                </Card.Body>
              </Card>
              
            
          



      

      </Row>
    </Container>

  );
}

export default View;
