import { TagCloud } from 'react-tagcloud'
import React, { useEffect, useState } from "react";
import axios from 'axios';
import configData from "./Config.json";



function Cloud()  {

  const[tagdata, setTagdata] = useState([]);
  useEffect(() => {
    getTags();
  }, []);
  const getTags = async () => {
    await axios.get(
      configData.TAGURL,
      JSON.stringify({}),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then(res => {
      setTagdata(res.data);
      //console.log(res.data);
      //console.log(data);
    });

  };
return (
  <TagCloud
    minSize={12}
    maxSize={35}
    tags={tagdata}
    onClick={tag => alert(`'${tag.value}' was selected!`)}
  />
)
}


export default Cloud;