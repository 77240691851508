import React, { useState, useEffect } from "react";
import format from "xml-formatter";
import { Container, Button, InputGroup, Row, Col, CloseButton } from 'react-bootstrap';



export default function StringTools() {
    
    const [source, setSource] = useState('');
    const [output, setOutput] = useState('');
    const sourceHandler = (e) => {
        setSource(e.target.value);
        //console.log(source);   
    }

    const xmlconvertHandler = (e) => {

        let newxml = "error";
        try {
            newxml = format(source);
        }
        catch  {
            setOutput('error');

        }
        finally {
            setOutput(newxml);

        }
    }

    const jsonconvertHandler = (e) => {
        try{
        var obj = JSON.parse(source);
        setOutput(JSON.stringify(obj, undefined, 2));
       // console.log(output);
        }catch{
            setOutput("error");
        }
    }
    return (
        <div className="m-3">

            <Row className="justify-content-center m-5" >

                <Col md={5}>
                <h2>Source</h2>
                    <textarea value={source} style={{ width: '100%', height: '700px' }} onChange={sourceHandler}> </textarea>


                </Col>
                <Col md={1}>
                <h2>&nbsp;</h2>
                <Button onClick={xmlconvertHandler} style={{ width: '100%', marginBottom:'10px'}}>XML </Button>
          
                <Button onClick={jsonconvertHandler} style={{ width: '100%', marginBottom:'10px' }}>JSON </Button>
                <Button onClick={() => setOutput(source.toUpperCase())} style={{ width: '100%', marginBottom:'10px' }}>UpperCase </Button>
                <Button onClick={() => setOutput(source.toLowerCase())}style={{ width: '100%', marginBottom:'10px' }}>LowerCase </Button>
                </Col>
                <Col md={5}>
                <h2>Output</h2>
                    <textarea value={output} style={{ width: '100%', height: '700px' }} readOnly={true}> </textarea>


                </Col>
            </Row>
        </div>
    )
}



